export class Constants {
    public static readonly AUDIO_DESCRIPTION = 'Audio Description';

    public static readonly AUDIO_DESCRIPTION_CONST = 'ad';

    // codec values for Audio Tracks
    public static readonly AUDIO_FORMAT_AAC = 'Stereo (AAC)';

    public static readonly AUDIO_FORMAT_DOLBY_DIGITAL = 'Dolby Audio™';

    public static readonly AUDIO_FORMAT_MPEG1 = 'Stereo (MPEG1)';

    public static readonly AUDIO_FORMAT_MPEG2 = 'Stereo (MPEG2)';

    public static readonly AUDIO_LANGUAGE_UNKNOWN = 'unknown';

    public static readonly CABLECO3_SOCU = 'Cableco3 SOCU';

    public static readonly DEFAULT_MAX_RETRIES = 3;

    public static readonly DEFAULT_RECOVERY_DELAY = 3000; // 3 secs in ms

    public static readonly DEFAULT_RETRY_DELAY = 3000; // 3 secs in ms

    public static readonly DEFAULT_RETRY_INCREMENTAL_DELAYS = [60, 240, 720, 1000, 2000, 4000];

    public static readonly DIMMER: string = 'dimmer';

    public static readonly END_OF_THRESHOLD = 0.1;

    public static readonly FIFTY_SECONDS = 50;

    public static readonly FIFTY_SECONDS_IN_MILLISECOND = 50 * 1000;

    public static readonly IN_HOME_LOCATION_STATUS = {
        SUCCESS: 'success',
        UNREACHABLE: 'unreachable',
        ERROR: 'error',
    };

    public static readonly LANGUAGE_MAPPING_NOT_AVAILABLE = 'LANGUAGE_MAPPING_NOT_AVAILABLE';

    public static readonly MILLISECOND_IN_ONE_SECOND = 1000;

    public static readonly MILLISECONDS_IN_FIFTEEN_MINUTES = 1000 * 60 * 15;

    public static readonly OFFSET_BODY_INFO_SERVICE_CALL = 24 * 60 * 60 * 1000;

    public static readonly OFFSET_CHANNELS_SERVICE_EXPIRY_TIME = 24 * 60 * 60 * 1000;

    public static readonly OFFSET_VPCONFIGURATIONS_SERVICE_CALL = 24 * 60 * 60 * 1000;

    public static readonly ORIGINAL_LANGUAGE = 'Original Language';

    public static readonly UNKNOWN_LANGUAGE = 'Unknown';

    public static readonly PLAY_PAUSE_BUTTON_LEFT_ALIGNMENT = 14;

    public static readonly PLAYER_RETRY_TIMEOUT = 3 * 1000;

    public static readonly ROOT_INTERACTIVE_LAYER = 'ROOT_INTERACTIVE_LAYER';

    // this is overall combination of socket, connection and read timeouts time
    // taking 5secs for each timeout, we have take 15 secs
    public static readonly SERVICE_CONNECTION_TIMEOUT = 15 * 1000; // 15 secs

    public static readonly SPACE = ' ';

    public static readonly SPACED_DASH = ' - ';

    // TODO: This value will be replaced.
    public static readonly SYSTEM_HEALTH_EVENT_INTERVAL = 60 * 1000;

    public static readonly TEXT_TRACK_KIND_CAPTION = 'caption';

    public static readonly THIRTY_SECONDS = 30;

    public static readonly THIRTY_SECONDS_IN_MILLISECOND = 30 * 1000;

    public static readonly UNKNOWN = 'unknown';
}
